import React, { useState } from 'react'
import mockup from './assets/Shot (3).png'
import mockupmobile from './assets/Shot (4).png'
import envelope from './assets/EnvelopeSimpleOpen.png'
import check from './assets/Done.png'
import bglines from './assets/Mask group.png'
import mobilelines from './assets/Vector 18 (1).png'
import clean from './assets/Rectangle 7377.png'
import variant from './assets/Variant3.png'
import Question from './Question'
import trophy from './assets/Trophy Cup.png'
import dollar from './assets/Group 1633.png'
import checkmark from './assets/Group (4).png'

function Home() {
  return (
    <div className='mt-[15%] lg:mt-[5%]'>
        <Hero />
        <Passion />
        <Action />
        <FAQs />
        <Waitlist />
    </div>
  )
}

export default Home


function Waitlist() {
    const [user, setUser] = useState({
        Email: ''
    });

    const data = (e) => {
        const { name, value } = e.target;
        setUser({...user, [name]: value});
    };

    const getdata = async (e) => {
        e.preventDefault();
        const { Email } = user;
        
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Email })
        };

        try {
            const res = await fetch('https://project-bottleup-default-rtdb.firebaseio.com/UserData.json', options);
            
            if (res.ok) {
                alert('Email added successfully');
                // Reset the email field after successful submission
                setUser({ Email: '' });
            } else {
                alert('An error occurred');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred');
        }
    };

    return (
        <div className='flex flex-col-reverse lg:flex-row bg-[#3D4447]'>
            <div>
                <img className='hidden lg:flex' src={mockup} alt="phone mockup" />
                <img className='lg:hidden w-full' src={mockupmobile} alt="phone mockup" />
            </div>
            <div className='text-center lg:text-left lg:max-w-[55%] p-[5%] lg:pr-[13%] py-[10%] lg:py-[5%]'>
                <h1 className='text-[30px] lg:text-[40px] font-semibold text-white'>Join our Waitlist</h1>
                <h3 className='text-white pt-[3%] pb-[5%] text-sm lg:text-base'>Be a part of BottleUp! Support our mission by partnering with us to expand coverage and boost participation.</h3>
                <form onSubmit={getdata}>
                    <div className='bg-white flex rounded-lg text-sm px-[3%] py-[3%]'>
                        <img className='pr-2' src={envelope} alt="envelope icon" />
                        <input 
                            type="email"
                            name='Email'
                            placeholder='Email Address'
                            className="w-full outline-none"
                            value={user.Email}
                            required
                            onChange={data}
                        />
                    </div>
                    <button type="submit" className="btn bg-primary text-white mt-[5%] font-medium w-full">
                        Start recycling!
                    </button>
                </form>
            </div>
        </div>
    );
}


function Action() {
    return(
        <div className='text-[#1E1E1E] py-[10%] lg:py-[3%] px-[5%]'> 
            <h1 className='font-semibold text-[#1E1E1E] lg:mx-[25%] text-[28px] lg:text-[40px] lg:leading-[50px] text-center'>Your Actions to Save the Earth in Quick and Easy Steps</h1> 
            <div className='py-5 flex flex-col lg:flex-row mt-[2%] flex-wrap lg:justify-between' >
            <div className='highlight p-[5%] lg:py-[3%] lg:p-[2%] lg:max-w-[32%]'>
                    <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Getting Started</p>
                    <p class="text-highlight text-sm">Download the BottleUP app from the App Store or Google Play, sign up or log in, and verify your email.</p>
                </div>
            </div>
            <div className='highlight p-[5%] lg:p-[2%] lg:py-[3%] lg:max-w-[32%]'>
            <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Navigating the App</p>
                    <p class="text-highlight text-sm">The BottleUP app’s dashboard displays your recycling stats, token balance, and recent activities.</p>
                </div>
            </div>
            <div className='highlight p-[5%] lg:p-[2%] lg:py-[3%] lg:max-w-[32%]'>
            <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Participating in PET Recycling</p>
                    <p class="text-highlight text-sm">In the BottleUP app, You’ll earn tokens for your recycling, and your balance updates immediately.</p>
                </div>
            </div>
            <div className='highlight p-[5%] lg:p-[2%] lg:py-[3%] lg:max-w-[32%] lg:mt-5'>
            <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Staying Informed</p>
                    <p class="text-highlight text-sm">Enable push notifications in the app to get updates on your recycling goals, new rewards, and activity alerts.</p>
                </div>
            </div>
            <div className='highlight p-[5%] lg:p-[2%] lg:py-[3%] lg:max-w-[32%] lg:mt-5'>
            <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Getting Help</p>
                    <p class="text-highlight text-sm">If you need further assistance or have specific issues, you can contact BottleUP’s support team through email.</p>
                </div>
            </div>
            <div className='highlight p-[5%] lg:p-[2%] lg:max-w-[32%] lg:mt-5'>
            <img src={check} alt='checkmark' />
                <div>
                    <p class="font-semibold text-lg pt-3">Maximizing Your Impact</p>
                    <p class="text-highlight text-sm">Recycle regularly to earn more tokens, track your progress using the app, and invite friends to join BottleUP to earn.</p>
                </div>
            </div>
            </div>
        </div>
    )
}


function Passion() {
    return (
        <div>
            <img className='absolute hidden lg:flex' src={bglines} />
            <img className='absolute flex lg:hidden pl-[5%]' src={mobilelines} />
          <div  className='bg-primary pt-[5%] pb-[10%] lg:py-[5%]'>
        <div
          className='flex flex-col-reverse lg:flex-row items-center justify-center'
        >
            
          <div  style={{zIndex: '9'}} className='px-[5%] lg:px-0'>
            <img src={clean} alt='phone mockup' />
          </div>
          <div className='lg:max-w-[50%] p-[5%]'>
            <h1 className='text-[28px] lg:text-[40px] lg:leading-[50px] font-semibold text-white'>
              Passion for a Sustainable Environment
            </h1>
            <h3 className='text-white pt-[3%] pb-[5%]'>
              Our aim is to cultivate a community-driven culture of environmental
              sustainability, with a particular emphasis on enhancing recycling
              practices through incentives and collective action.
            </h3>
            <button className='btn bg-[#CCE1D7] font-medium text-[#1E1E1E]'>
              Start recycling!
            </button>
          </div>
        </div>
      </div>  
        </div>
      
    );
  }
  
  console.log('Passion component rendered');

function Hero() {
    return(
        <div className='flex flex-col items-center py-[10%] lg:py-[5%] px-[5%]'>
            <img className='absolute hidden lg:flex lg:left-[20%] mt-[5%]' src={checkmark} />
            <h1 className='text-3xl lg:text-6xl font-semibold text-center lg:leading-[70px]'>Why Waste Plastic? <br />Recycle <span className='text-primary'>and Earn</span></h1>
            <h3 className='text-center py-[5%] lg:py-[2%] lg:max-w-[45%]'>Welcome to BottleUp! Your journey to a more seamless, secure, and highly personalized recycling experience begins right here</h3>
            <button className='btn bg-primary text-white'>Start Recycling!</button>
            <img className='absolute right-[15%] mt-[20%] hidden lg:flex' src={trophy} />            
            <img className='absolute hidden lg:flex left-[28%] mt-[24%]' src={dollar} />
            <div className="bg-[#34A85333] rounded-[24px] mt-[9%]">
                <img src={variant} alt="phones" />
            </div>
            
        </div>
    )
}

function FAQs() {
    
    return(
        <div style={{
            backgroundImage: `linear-gradient(to bottom, #F6F6F6 60%, #FFFFFF 40%)`,
            backgroundSize: "100% 100%",
           }} className=' px-[5%] lg:px-0 flex flex-col lg:flex-row py-[10%] lg:py-[5%]'>
            <h1 className='lg:w-[50%] pb-5 lg:pb-0 font-semibold text-3xl lg:leading-[50px] lg:text-[40px] text-center lg:text-left lg:pl-[25%]'>Frequently <br className='hidden lg:flex' />Asked <br className='hidden lg:flex' />Questions</h1>
            <div className='lg:w-[50%]'>
                <Question
                    question="How do I sign up?"
                    answer="You can sign up by downloading our app and completing a simple registration process. You’ll need to provide basic information to create your account and start participating in the recycling program."
                />
                <Question
                    question="What can I do on my user dashboard?"
                    answer="On your dashboard, you can view your recycling history, track the rewards you’ve earned, and
                    access educational resources about recycling and the blockchain system. The dashboard is
                    designed to be user-friendly and easy to navigate."
                />
                <Question
                    question="How does BottleUp reward its users?"
                    answer="BottleUp isn’t just an innovative project; it is a forward-thinking initiative that is deeply committed to sustainability. Our goal is to harness and maximize human efforts to actively protect and preserve our environment."
                />
                <Question
                    question="How can I locate drop-off points or centers?"
                    answer="We partner with local recycling centers to integrate their systems with ours. If you’re done
                    gathering your bottles, you can search on the integrated map to find the nearest center for a drop"
                />
                <Question
                    question="What types of rewards can I earn?"
                    answer="You can earn digital tokens for your recycling efforts. These rewards can be redeemed for cash, special offers, or other incentives from participating retailers and brands."
                />
                <Question
                    question="How are rewards issued and redeemed?"
                    answer="Rewards are issued through smart contracts once your recycling activities are verified. You can redeem your rewards by following the instructions provided on the platform or through participating retailers."
                />
                <Question
                    question="What are the benefits of using this system?"
                    answer="The system increases transparency in recycling, ensures fair reward distribution, and encourages more people to recycle. It also reduces fraud and improves the efficiency of recycling processes through blockchain and IoT technologies."
                />
                <Question
                    question="Is my personal information secure?"
                    answer="Yes, your personal information is protected with strong security measures. We use encryption and other security protocols to ensure your data is safe and private."
                />
                <Question
                    question="Who can I contact if I have more questions or need support?"
                    answer="For any additional questions or support, please reach out to our customer service team via email or our online support portal. We’re here to help with any inquiries or issues you may have."
                />
            </div>
        </div>
    )
}