import { Link } from 'react-router-dom';
import notFoundImage from './assets/Group (2).png';

const NotFound = () => {
  return (
    <div
      className="flex flex-col justify-between items-center mt-[15%] lg:mt-[5%] w-full text-center p-[5%] lg:pt-[2%]"
    >
      <img className="lg:max-w-[40%]" src={notFoundImage} alt="404 Error" />
      <h3 className="text-light py-3">
        Oops! This page has gone on vacation and forgot to leave a forwarding address
      </h3>
      <Link to="/">
        <button className="text-primary font-semibold text-lg animate-pulse">
          Go Home
        </button>
      </Link>
    </div>
  );
};

export default NotFound;