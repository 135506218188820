import React from 'react';
import logo from './assets/logo.png';
import { Link } from 'react-router-dom';
import logos from './assets/Icons.png'

function Footer() {
  return (
    <div className="lg:py-[3%] py-[10%] px-[5%] max-w-full bg-[#F9F9F9] items-start">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div className="lg:max-w-[30%]">
          <img className="max-w-[40%]" src={logo} alt="brand logo" />
          <h4 className="text-light  py-3">
            Join BottleUp today! Revolutionizing recycling with Blockchain rewards
          </h4>
        </div>
        <div>
          <div className="flex pt-[5%]">
            <ul className='w-full'>
              <li className="font-normal text-highlight pb-2">Company</li>
              <li className='font-medium pb-2'>About Us</li>
              <li className='font-medium pb-2'>FAQs</li>
              <li className='font-medium'>Join our waitlist</li>
            </ul>
          </div>
        </div>
        <div>
            <ul>
            <li className="font-normal text-highlight pb-2 pt-[5%] lg:pt-0">Contact Us</li>
            <li className='font-medium'>contact@bottleup.com</li>
            <li className='pt-3'><img src={logos} alt="social icons" /></li>
            </ul>
          </div>
      </div>
      <div className="bg-[#E4E7EC] lg:h-[.7px] h-[.5px] my-5"></div>
      <div className="flex flex-col lg:flex-row lg:px-[5%] lg:justify-between lg:items-center py-2">
        <div className="flex flex-col text-center w-full">
          <h4 className='text-[#1D2739]'>
            &copy; 2024 BottleUp. All rights reserved.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Footer;