import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { Link } from "react-router-dom";
import logo from "./assets/logo.png";
import { Bounce } from "react-awesome-reveal";

function Header() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isHeaderFloating, setIsHeaderFloating] = useState(false);

  const handleIconClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsHeaderFloating(true);
      } else {
        setIsHeaderFloating(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Detect if viewport width is less than 768px (typical tablet size)
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Sidebar = () => {
    return (
      <div
        className={`fixed top-0 py-5 px-5 md:px-[5%] right-0 h-screen w-[250px] bg-white shadow-md z-50 ${
          isSidebarOpen ? "open" : "closed"
        }`}
      >
        <div>
          <div className="ml-[87%]" onClick={handleCloseSidebar}>
            <CloseIcon className="close-icon" />
          </div>
          <ul className="sidebar-links text-black text-lg flex flex-col">
            <Link to="/" onClick={handleCloseSidebar}>
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                Home
              </li>
            </Link>
            <Link to="/about" onClick={handleCloseSidebar}>
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                About Us
              </li>
            </Link>
            <Link to="/features" onClick={handleCloseSidebar}>
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                FAQs
              </li>
            </Link>
            <Link to="/news" onClick={handleCloseSidebar}>
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                Contact
              </li>
            </Link>
            <li onClick={handleCloseSidebar}>
              <button className="px-[10px] py-[8px] bg-primary rounded-[10px] text-white hover:bg-secondary hover:text-white transition-colors duration-300 active:bg-secondary active:text-white">
                Join the Waitlist
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={`bg-[#F7F9FC] w-[100%] flex items-center justify-between px-5 md:px-[5%] md:h-20 h-16 transition-all duration-300 fixed top-0 left-0 right-0 z-50`}
      >
        <Link to="/">
          <div className="flex items-center">
            <Bounce>
              <img src={logo} alt="logo" />
            </Bounce>
          </div>
        </Link>
        {isMobileView && (
          <div className="md:hidden">
            <div className="sidebar-trigger" onClick={handleIconClick}>
              {isSidebarOpen ? <CloseIcon className="close-icon" /> : <DehazeIcon />}
            </div>
          </div>
        )}
        <div
          className={`hidden md:flex ${
            isMobileView && !isSidebarOpen ? "hidden" : ""
          }`}
        >
          <ul className="bg-white font-medium flex items-center py-[10px] px-[40px] rounded-[100px]">
            <Link to="/" className="pr-4">
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                Home
              </li>
            </Link>
            <Link to="/about" className="pr-4">
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                About Us
              </li>
            </Link>
            <Link to="/features" className="pr-4">
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                FAQs
              </li>
            </Link>
            <Link to="/news">
              <li className="hover:bg-primary hover:text-white transition-colors duration-300">
                Contact
              </li>
            </Link>
          </ul>
        </div>
        <button className="btn text-white rounded-[10px] bg-primary hidden lg:flex items-center justify-center">
          Join the Waitlist
        </button>
      </div>
      {isMobileView && isSidebarOpen && <Sidebar />}
    </div>
  );
}

export default Header;