import React, { useState } from 'react';

const Question = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white rounded-lg px-[32px] py-[24px] mb-4 lg:w-[80%] question">
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleDropdown}>
        <div className="font-semibold text-[#1D1F1E]">{question}</div>
        <div className="ml-2 flex items-center justify-center bg-primary text-white h-[24px] w-[24px] rounded">{isOpen ? '-' : '+'}</div>
      </div>
      {isOpen && (
        <div className="mt-2">
          <div className="text-[#545454]">{answer}</div>
        </div>
      )}
    </div>
  );
};

export default Question;