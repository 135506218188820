import NotFound from "./NotFound";
import React from 'react' 
import Header from './Header' 
import Footer from './Footer' 
import Home from './Home' 
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import About from './About' 
import Support from "./Support";

function App() {
  return (
    <BrowserRouter>
      <div>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/support" element={<Support />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App